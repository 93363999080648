/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useState } from 'react';
import { Box, css, TableCell, TableRow, Theme } from '@mui/material';

const useStyles = {
    tableRow: (archived: boolean) => (theme: Theme) => css`
        cursor: pointer;
        &:hover {
            background-color: #fafbfc !important;
        }
        ${archived &&
        css`
            background-color: ${theme.palette.grey[100]};
        `}
    `,
    visibilityHidden: css`
        & button {
            visibility: visible;
        }
    `,
    visibilityVisible: css`
        & button {
            visibility: visible;
        }
    `,
};

export interface IListItemContainerProps {
    entityId: string;
    renderFirstColumn?: (entityId: string) => JSX.Element;
    renderLastColumn?: (entityId: string) => JSX.Element;
    renderItemNavigation?: (entityId: string) => JSX.Element;
    enableHover?: boolean;
    handleHoverState?: (show: boolean) => void;
    onClick?: () => void;
    children: ReactNode;
    archived?: boolean;
}

export const ListItemContainer: FC<IListItemContainerProps> = ({
    entityId,
    renderFirstColumn,
    renderLastColumn,
    renderItemNavigation,
    children,
    enableHover = false,
    archived = false,
    ...props
}) => {
    const [actionsVisibilityClass, setActionsVisibilityClass] = useState<
        'visibilityVisible' | 'visibilityHidden'
    >('visibilityHidden');

    const handleHoverState = (show: boolean) => {
        setActionsVisibilityClass(
            show ? 'visibilityVisible' : 'visibilityHidden'
        );
    };

    return (
        <TableRow
            hover={enableHover}
            css={useStyles.tableRow(archived)}
            onMouseEnter={() => enableHover && handleHoverState(true)}
            onMouseLeave={() => enableHover && handleHoverState(false)}
            {...props}
        >
            {renderFirstColumn && (
                <TableCell>{renderFirstColumn(entityId)}</TableCell>
            )}
            {children}
            {renderItemNavigation && (
                <TableCell className="MuiTableCell-actions">
                    <Box>{renderItemNavigation(entityId)}</Box>
                </TableCell>
            )}
            {renderLastColumn && (
                <TableCell className="MuiTableCell-actions">
                    <Box css={useStyles[actionsVisibilityClass]}>
                        {renderLastColumn(entityId)}
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};
